import { AccountInfo, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { FluentProvider } from '@fluentui/react-components';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { Constants } from './Constants';
import MissingEnvVariablesError from './components/views/MissingEnvVariablesError';
import './index.css';
import { AuthHelper } from './libs/auth/AuthHelper';
import { store } from './redux/app/store';

import React from 'react';
import { getMissingEnvVariables } from './checkEnv';
import { semanticKernelLightTheme } from './styles';

if (!localStorage.getItem('debug')) {
    localStorage.setItem('debug', `${Constants.debug.root}:*`);
}

let container: HTMLElement | null = null;


document.addEventListener('DOMContentLoaded', () => {


    if (!container) {
        container = document.getElementById('root');
        if (!container) {
            throw new Error('Could not find root element');
        }
        const root = ReactDOM.createRoot(container);
       
        const missingEnvVariables = getMissingEnvVariables();
        const validEnvFile = missingEnvVariables.length === 0;

        let msalInstance: IPublicClientApplication | null = null;
        if (validEnvFile) {
            msalInstance = new PublicClientApplication(AuthHelper.msalConfig);
            void msalInstance.handleRedirectPromise().then((response) => {
                
                if (response) {
                    msalInstance?.setActiveAccount(response.account);
                }
            });
//this is for iframe application
            window.addEventListener("message", (event) => {
                console.log(event.origin);
                console.log(process.env.REACT_APP_AllowedIframeOriginUrl);
                // check the origin of the data
                if (event.origin === process.env.REACT_APP_AllowedIframeOriginUrl) {
                    
          
            
           //const a : AccountInfo = event && event.data && event.data.account?event.data.account:null;
           /* eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment */
           const a1  =  event.data??null;
           
           /* eslint-disable-next-line  @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment */
           const a : AccountInfo =  a1.account??null;            
                    // attempt SSO
                    msalInstance?.ssoSilent({
                        account: a
                    }).then((response) => {                        
                        msalInstance?.setActiveAccount(response.account);
                        // do something with response
                    }).catch(error => {
                        // handle errors
                        console.log(error);
                    });
                }
            });//end of listener

        }

        root.render(
            <React.StrictMode>
                {!validEnvFile && <MissingEnvVariablesError missingVariables={missingEnvVariables} />}
                {validEnvFile && (
                    <ReduxProvider store={store}>
                        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                        <MsalProvider instance={msalInstance!}>
                            <FluentProvider className="app-container" theme={semanticKernelLightTheme}>
                                <App />
                            </FluentProvider>
                        </MsalProvider>
                    </ReduxProvider>
                )}
            </React.StrictMode>,
        );
    }
});
